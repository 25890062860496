var divs = ['report_charges', 'report_payments', 'report_payment_audits', 'report_patient_audits', 'report_deposit_item_audits'];

var visibleDivId = null;

window.toggleVisibility = function (divId) {
    if (visibleDivId === divId) {
        console.log(divId);
        //visibleDivId = null;
    } else {
        visibleDivId = divId;
    }
    hideNonVisibleDivs();
}

window.hideNonVisibleDivs = function () {
    var i, divId, div;
    for (i = 0; i < divs.length; i++) {
        divId = divs[i];
        // console.log(divId);
        div = document.getElementById(divId);
        if (visibleDivId === divId) {
            div.style.display = "block";
        } else {
            div.style.display = "none";
        }
    }
}
