// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import Rails from "@rails/ujs";
import jquery from "jquery";
// import * as ActiveStorage from "@rails/activestorage";
// import "channels";
import "./controllers";

Rails.start();
window.Rails = Rails;
window.jQuery = jquery;
window.$ = jquery;
// ActiveStorage.start();

import("./flash");
import("./popper");
import("./reports");

import {config, library, dom} from '@fortawesome/fontawesome-svg-core'
import {faUser as fasFaUser} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt as fasFaTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {faSignOutAlt as fasFaSignOutAlt} from '@fortawesome/free-solid-svg-icons'
import {faEye as fasFaEye} from '@fortawesome/free-solid-svg-icons'
import {faPlus as fasFaPlus} from '@fortawesome/free-solid-svg-icons'
import {faChevronRight as fasFaChevronRight} from '@fortawesome/free-solid-svg-icons'
import {faChevronLeft as fasFaChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {faSpinner as fasFaSpinner} from '@fortawesome/free-solid-svg-icons'
import {faUsers as fasFaUsers} from '@fortawesome/free-solid-svg-icons'
import {faCog as fasFaCog} from '@fortawesome/free-solid-svg-icons'
import {faSearch as fasFaSearch} from '@fortawesome/free-solid-svg-icons'
import {faHospitalUser as fasHospitalUser} from '@fortawesome/free-solid-svg-icons'
import {faHandHoldingUsd as fasHandHoldingUsd} from '@fortawesome/free-solid-svg-icons'
import {faTimesCircle as fasTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {faTimes as fasTimes} from '@fortawesome/free-solid-svg-icons'
import {faCircleDollarToSlot as fasCircleDollarToSlot} from '@fortawesome/free-solid-svg-icons'
import {faEdit as fasFaEdit} from '@fortawesome/free-solid-svg-icons'
import {faLink as fasFaLink} from '@fortawesome/free-solid-svg-icons'
import {faNoteSticky as fasFaNoteSticky} from '@fortawesome/free-solid-svg-icons'
import {faPenToSquare as fasFaPenToSquare} from '@fortawesome/free-solid-svg-icons'
import {faBan as fasFaBan} from '@fortawesome/free-solid-svg-icons'
import {faFileExport as fasFaFileExport} from '@fortawesome/free-solid-svg-icons'
import {faEllipsisVertical as fasFaEllipsisVertical} from '@fortawesome/free-solid-svg-icons'
import {faHourglassStart as fasFaHourglassStart} from '@fortawesome/free-solid-svg-icons'
import {faCheck as fasFaCheck} from '@fortawesome/free-solid-svg-icons'
import {faFileInvoiceDollar as fasFaFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons'
import {faPrint as fasFaPrint} from '@fortawesome/free-solid-svg-icons'
import {faComment as fasFaComment} from '@fortawesome/free-solid-svg-icons'
import {faCircleArrowDown as fasFaCircleArrowDown} from '@fortawesome/free-solid-svg-icons'

config.mutateApproach = 'sync'
library.add(fasFaUser, fasFaTrashAlt, fasFaPlus, fasFaSignOutAlt, fasFaChevronRight, fasFaChevronLeft, fasFaEye,
    fasFaSpinner, fasFaUsers, fasFaCog, fasFaSearch, fasHospitalUser, fasHandHoldingUsd, fasTimesCircle, fasTimes,
    fasCircleDollarToSlot, fasFaEdit, fasFaLink, fasFaNoteSticky, fasFaPenToSquare, fasFaBan, fasFaFileExport,
    fasFaEllipsisVertical, fasFaHourglassStart, fasFaCheck, fasFaFileInvoiceDollar, fasFaPrint, fasFaComment,
    fasFaCircleArrowDown)
dom.watch()
